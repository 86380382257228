import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';
import router from '@/router'

const useAuth = defineStore( 'auth' , {
    state: () => {
        return {
            token: useStorage('token', ''),
            usuario: useStorage('usuario', {})
        }
    },
    getters: {
        getToken: (state) => state.token
    },
    actions: {
        updateUsuarioStore(usuario){
            const { nombre ,apellido_paterno ,apellido_materno ,curp ,fecha_nacimiento ,genero ,correo ,celular ,idAsociacion ,idClub ,rol ,token } = usuario;
            this.usuario.nombre = nombre;
            this.usuario.apellido_paterno = apellido_paterno;
            this.usuario.apellido_materno = apellido_materno;
            this.usuario.curp = curp;
            this.usuario.fecha_nacimiento = fecha_nacimiento;
            this.usuario.genero = genero;
            this.usuario.correo = correo;
            this.usuario.celular = celular;
            this.usuario.idAsociacion = idAsociacion;
            this.usuario.idClub = idClub;
            this.usuario.rol = rol;
            this.usuario.token = token;
        },
        async login(curp, password){
            const uri = `${process.env.VUE_APP_BASE_URL}/usuarios/login`;
            try {
                const response = await fetch( uri, {
                    method: 'POST',
                    headers: {
                        'Content-type' : 'Application/json'
                    },
                    body: JSON.stringify({
                        'curp' : curp,
                        'password' : password 
                    })
                });
                const { token , usuario, msg } = await response.json();
                if(response.status === 200){
                    this.token = token;
                    this.usuario = usuario;
                    return true;
                } else if(response.status === 401) {
                    throw new Error(msg);
                }
            } catch (error) {
                return error;
            }
        },
        logOut(){
            try {
                    router.push( { name : 'home'} );
                    this.token = '';
                    this.usuario = '';
            } catch (error) {
                return error;
            }
        }
    }
})

export default useAuth
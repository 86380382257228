<template >
  <header class="w-full overflow-x-hidden">
    <NavigationBar/>
  </header>
  <main class="w-full overflow-x-hidden">
    <router-view v-slot="{ Component }">
      <!-- <transition name="fade" mode="out-in"> -->
        <component :is="Component" />
      <!-- </transition> -->
    </router-view>
  </main>
  <FooterComponent/>
</template>

<script>
import NavigationBar from '@/components/NavigationBarComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
export default{
  components: { NavigationBar, FooterComponent }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}


</style>
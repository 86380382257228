import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'

import Vuelidate from 'vuelidate';
import './styles/styles.css'
import 'flowbite';
import  VueGoogleMaps from '@fawmi/vue-google-maps';
import VueTheMask from 'vue-the-mask';
import Vue3Html2pdf from 'vue3-html2pdf';

const pinia = createPinia();
createApp(App)
.use(router)
.use(pinia)
.use(Vuelidate)
.use(Vue3Html2pdf)
.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    },
})
.use(VueTheMask)
.mount('#app')

import { createRouter, createWebHistory } from 'vue-router'
import useAuth from '@/store/auth'
import { Buffer } from 'buffer';
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "Home" */ '../components/InicioComponent.vue'),
    meta : {
      requireAuth : false
    }
  },
  {
    path: '/quienes-somos',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../components/SomosComponent.vue'),
    meta : {
      requireAuth : false
    }
  },
  {
    path: '/horarios',
    name: 'horarios',
    component: () => import(/* webpackChunkName: "horarios" */ '../components/HorariosComponent.vue'),
    meta : {
      requireAuth : false
    }
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: () => import(/* webpackChunkName: "contacto" */ '../components/ContactComponent.vue'),
    meta : {
      requireAuth : false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../components/Registro/LoginComponent.vue'),
    meta : {
      requireAuth : false
    }
  },
  {
    path: '/registro',
    name: 'registro',
    component: () => import(/* webpackChunkName: "registro" */ '../components/Registro/RegistroComponent.vue'),
    meta : {
      requireAuth : false
    }
  },
  {
    path: '/olvide-password',
    name: 'olvide-password',
    component: () => import(/* webpackChunkName: "olvide-password" */ '../components/Registro/OlvidePasswordComponent.vue'),
    meta : {
      requireAuth : false
    }
  },
  {
    path: '/renueva-password/:token',
    name: 'renueva-password',
    component: () => import(/* webpackChunkName: "renueva-password" */ '../components/Registro/ResetPasswordComponent.vue'),
    meta : {
      requireAuth : false
    }
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "about" */ '../views/TorneoView.vue'),
    children: [
      {
        path: '/torneo/:idTorneo',
        name: 'informacion-torneo',
        component: () => import(/* webpackChunkName: "about" */ '../components/Torneo/InformacionTorneoComponent.vue'),
        meta: { requireAuth: false },
      },
      {
        path: '/torneo/:idTorneo/categoria/:categoria/rama/:rama/division/:division',
        name: 'grafica',
        component: () => import(/* webpackChunkName: "about" */ '../components/Torneo/GraficaComponent.vue'),
        meta: { requireAuth: false }
      },
      {
        path: '/torneo/:idTorneo/graficas',
        name: 'graficas',
        component: () => import(/* webpackChunkName: "about" */ '../components/Torneo/ViewGraficasComponent.vue'),
        meta: { requireAuth: false }
      }
    ],
    meta : {
      requireAuth : false
    }
  },
  {
    path: '/usuario',
    name: 'usuario',
    component: () => import(/* webpackChunkName: "about" */ '../views/UserView.vue'),
    children: [
      {
        path: '/inicio',
        name: 'inicio',
        component: () => import(/* webpackChunkName: "about" */ '../components/Usuario/HomeComponent.vue'),
        meta: { requireAuth: true }
      },
      {
        path: '/identificacion',
        name: 'identificacion',
        component: () => import(/* webpackChunkName: "about" */ '../components/Usuario/IdentificacionComponent.vue'),
        meta: { requireAuth: true }
      },
      {
        path: '/registro-torneo/:idTorneo',
        name: 'registro-torneo',
        component: () => import(/* webpackChunkName: "about" */ '../components/Usuario/RegistroTorneoComponent.vue'),
        meta: { requireAuth: true }
      },
      {
        path: '/registro-pesaje/:idTorneo',
        name: 'registro-pesaje',
        component: () => import(/* webpackChunkName: "about" */ '../components/Usuario/RegistroPesajeComponent.vue'),
        meta: { requireAuth: true }
      },
      {
        path: '/perfil/:curp',
        name: 'perfil',
        component: () => import(/* webpackChunkName: "about" */ '../components/CrudUsuarios/ModificarUsuarioComponent.vue'),
        meta: { requireAuth: true },
      },
    ],
    meta : {
      requireAuth : true
    }
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "about" */ '../views/CrudTorneoView.vue'),
    children: [
      {
        path: 'torneos',
        name: 'lista-torneos',
        component: () => import(/* webpackChunkName: "about" */ '../components/CrudTorneo/ListaTorneosComponent.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'torneos/:idTorneo',
        name: 'modificar-torneo',
        component: () => import(/* webpackChunkName: "about" */ '../components/CrudTorneo/ModificarTorneoComponent.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'torneos/crear',
        name: 'nuevo-torneo',
        component: () => import(/* webpackChunkName: "about" */ '../components/CrudTorneo/CrearTorneoComponent.vue'),
        meta: { requireAuth: true },
      },
    ],
    meta: { requireAuth: true },
  },
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/CrudTorneoView.vue'),
    children: [
      {
        path: 'torneos',
        name: 'lista-torneos',
        component: () => import(/* webpackChunkName: "about" */ '../components/CrudTorneo/ListaTorneosComponent.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'torneos/:idTorneo',
        name: 'modificar-torneo',
        component: () => import(/* webpackChunkName: "about" */ '../components/CrudTorneo/ModificarTorneoComponent.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'torneos/crear',
        name: 'nuevo-torneo',
        component: () => import(/* webpackChunkName: "about" */ '../components/CrudTorneo/CrearTorneoComponent.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'usuarios',
        name: 'lista-usuarios',
        component: () => import(/* webpackChunkName: "about" */ '../components/CrudUsuarios/ListaUsuariosComponent.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'usuarios/:curp',
        name: 'modificar-usuario',
        component: () => import(/* webpackChunkName: "about" */ '../components/CrudUsuarios/ModificarUsuarioComponent.vue'),
        meta: { requireAuth: true },
      },
    ],
    meta: { requireAuth: true },
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "about" */ '../views/CrudTorneoView.vue'),
    children: [
      {
        path: 'competidores/:torneo/:idTorneo',
        name: 'lista-competidores',
        component: () => import(/* webpackChunkName: "about" */ '../components/CrudCompetidores/ListaCompetidoresComponent.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'competidores/:torneo/:idTorneo/:idCompetidor',
        name: 'modificar-competidor',
        component: () => import(/* webpackChunkName: "about" */ '../components/CrudCompetidores/ModificarCompetidorComponent.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'competidores/:torneo/:idTorneo/agregar', 
        name: 'agregar-competidor',
        component: () => import(/* webpackChunkName: "about" */ '../components/CrudCompetidores/CrearCompetidorComponent.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'competidoresAgrupados/:torneo/:idTorneo',
        name: 'lista-competidoresAgrupados',
        component: () => import(/* webpackChunkName: "about" */ '../components/CrudCompetidores/CompetidoresXCategoriaComponent.vue'),
        meta: { requireAuth: true },
      },
    ],
    meta: { requireAuth: true },
  }
] 

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    window.scrollTo(0,0);
  }
})

const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(Buffer.from(base64, "base64").toString("ascii").split("").map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

router.beforeEach( ( to, from , next) => {
  const auth = useAuth()
  const isAuth = auth.token
  if ( !to.meta.requireAuth ) {
    next();
  }else{
    if(isAuth !== ''){
      const jwtPayload = parseJwt(isAuth);
      if (jwtPayload.exp < Date.now()/1000) {
        auth.logOut();
        next({ name : 'login'});
      }else{
        next();
      }
    }else{
      next({ name : 'login'});
    }
  }

  
  
})



export default router

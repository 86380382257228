<template>
    <nav class="bg-white border-gray-200 fixed w-screen z-30">
        <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-2 px-2" >
        <router-link :to="{ name : 'home' }" class="flex items-center">
            <img src="../assets/Judogui&Nombre.svg" class="h-8 mr-1" alt="Judotecnia Logo" />
        </router-link>
        <div class="flex items-center lg:order-2 mr-3">
            <router-link :to="{ name : 'login'}" v-if="!auth.token" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2 text-center mr-1 lg:mr-3">
                Iniciar sesión
            </router-link>
            <button v-if="auth.token" id="user-menu-button" type="button" @click.prevent="tooglePerfil"
                class="flex ml-3 mr-1 text-sm bg-gray-800 rounded-full lg:mr-0 focus:ring-4 focus:ring-gray-300 transition-all hover:scale-105">
                <img class="w-8 h-8 rounded-full" src="../assets/user.png" alt="Icono Usuario"/>
            </button>
            <!-- Dropdown menu -->
            <div class="absolute z-50 top-14 right-5 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow border border-gray-300" id="user-dropdown" :class="{ hidden: showPerfil }"
            >
                <div class="px-4 py-3">
                    <span class="block text-sm text-gray-900">{{auth.usuario.nombre}}</span>
                    <span class="block text-sm text-gray-500 truncate">{{auth.usuario.curp}}</span>
                </div>
                <ul v-if="auth.usuario" class="py-2" aria-labelledby="user-menu-button">
                    <li>
                        <router-link :to="{ name : 'inicio' }" @click.prevent="tooglePerfil" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" >
                            Home
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name : 'identificacion' }" @click.prevent="tooglePerfil" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" >
                            Mi identificación
                        </router-link>
                    </li>
                    <li v-if="auth.usuario.rol == 'ADMIN_ROL'">
                        <router-link :to="{ name : 'lista-torneos' }" @click.prevent="tooglePerfil" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" >
                            Torneos
                        </router-link>
                    </li>
                    <li v-if="auth.usuario.rol == 'ADMIN_ROL'">
                        <router-link :to="{ name : 'lista-usuarios' }" @click.prevent="tooglePerfil" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" >
                            Usuarios
                        </router-link>
                    </li>
                    <li v-if="auth.usuario.curp">
                        <router-link :to="{ name : 'perfil' , params : {  curp : auth.usuario.curp }   }" @click.prevent="tooglePerfil" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" >
                            Perfil
                        </router-link>
                    </li>
                    <li>
                        <a @click="logOut" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" >
                            Cerrar sesión
                        </a>
                    </li>
                </ul>
            </div>
            <button type="button" @click.prevent="toogleMenu" class="inline-flex items-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" >
                <span class="sr-only">Open main menu</span>
                <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" > <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"></path>
                </svg>
            </button>
        </div>
        <div
            :class="{ hidden: showMenu }"
            class="items-center justify-between w-full lg:flex lg:w-auto lg:order-1"
            id="mobile-menu-2"
        >
            <ul
            class="flex flex-col font-medium p-4 lg:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 lg:flex-row lg:space-x-8 lg:mt-0 lg:border-0 lg:bg-white"
            >
            <li>
                <RouterLink :to =" { name: 'home' } " @click="toogleMenu" 
                active-class="text-white bg-blue-700 lg:bg-transparent lg:text-blue-700"
                class="nav_link block py-2 pl-3 pr-4 lg:p-0 rounded text-gray-900  hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-blue-700">
                    Inicio
                </RouterLink>
            </li>
            <li>
                <RouterLink :to =" { name: 'about' } " @click="toogleMenu"
                active-class="text-white bg-blue-700  lg:bg-transparent lg:text-blue-700"
                class="nav_link block py-2 pl-3 pr-4 lg:p-0 rounded text-gray-900  hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-blue-700 ">
                    Quienes somos
                </RouterLink>
            </li>
            <li>
                <RouterLink :to =" { name: 'horarios' } " @click="toogleMenu"
                active-class="text-white bg-blue-700  lg:bg-transparent lg:text-blue-700"
                class="nav_link block py-2 pl-3 pr-4 lg:p-0 rounded text-gray-900  hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-blue-700 ">
                    Horarios
                </RouterLink>
            </li>
            <li>
                <RouterLink :to =" { name: 'contacto' } " @click="toogleMenu"
                active-class="text-white bg-blue-700  lg:bg-transparent lg:text-blue-700"
                class="nav_link block py-2 pl-3 pr-4 lg:p-0 rounded text-gray-900  hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-blue-700 ">
                    Contacto
                </RouterLink>
            </li>
            </ul>
        </div>
        </div>
    </nav>
</template>
<script>
import { ref } from "vue";
import useAuth from '@/store/auth';
export default {
    name: "NavigationBar",
    setup() {
        const auth = useAuth();
        const showPerfil = ref(true);
        const showMenu = ref(true);
        const tooglePerfil = () => showPerfil.value = !showPerfil.value;
        const toogleMenu = () => showMenu.value = !showMenu.value;
        const logOut = () => {
            tooglePerfil();
            auth.logOut();
        }
        
        return{
            showPerfil,
            showMenu,
            tooglePerfil,
            toogleMenu,
            auth,
            logOut
        }
    }
};
</script>
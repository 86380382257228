<template>
    
<footer class="bg-gray-100">
    <div class="mx-auto w-full max-w-screen-xl p-4 py-4 lg:py-4">
        <div class="md:flex md:justify-between">
            <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
                <div>
                    <h4 class="mb-6 text-sm font-semibold text-gray-900 uppercase">Resources</h4>
                    <ul class="text-gray-600 font-medium">
                        <li class="mb-2">
                            <RouterLink :to =" { name: 'home' } "
                            class="hover:underline">
                                Inicio
                            </RouterLink>
                        </li>
                        <li class="mb-2">
                            <RouterLink :to =" { name: 'about' } "
                            class="hover:underline">
                                Quienes somos
                            </RouterLink>
                        </li>
                        <li class="mb-2">
                            <RouterLink :to =" { name: 'horarios' } "
                            class="hover:underline">
                                Horarios
                            </RouterLink>
                        </li>
                        <li class="mb-2">
                            <RouterLink :to =" { name: 'contacto' } "
                            class="hover:underline">
                                Contacto
                            </RouterLink>
                        </li>
                    </ul>
                </div>
                <div>
                    <h4 class="mb-6 text-sm font-semibold text-gray-900 uppercase">Siguenos</h4>
                    <ul class="text-gray-600 font-medium">
                        <li class="mb-4">
                            <a href="https://www.facebook.com/Judotecnia" class="hover:underline" target="_blank" style="color: #3b5998;" >Facebook</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <hr class="my-1 border-gray-200 sm:mx-auto lg:my-1" />
        <div class="sm:flex sm:items-center sm:justify-between">
            <div class="flex mb-1 space-x-6 sm:justify-center sm:mt-0">
                <a href="https://www.facebook.com/Judotecnia" target="_blank"  class="text-gray-500 hover:text-gray-900">
                    <svg class="w-5 h-5" fill="#3b5998" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" /></svg>
                    <span class="sr-only">Facebook page</span>
                </a>
            </div>
            <span class="text-sm text-gray-500 sm:text-center">© 2023 
                <RouterLink :to =" { name: 'home' } " class="hover:underline">
                    . Todos los derechos reservados
                </RouterLink>
            </span>
        </div>
    </div>
</footer>

</template>
<script>
export default{
    setup(){

    }
}
</script>
<style scoped>
</style>